import React from "react";
import IMAGES from "./assets";
import "./styles.css";

const Inicio = () => {

   
const pantallaNegra= {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'black',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}
const containerStyle= {
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${IMAGES.background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}

const imagenLogoCentro= {
    width:"150px",
    height:"150px",
    float:"center",
    content: `url(${IMAGES.logororomiem})`,
    alignContent: 'center',
    paddingTop: '30vh',
    display: 'inline-grid'
}

    return (
        <div
        style={containerStyle}>
            <div
            style={{
            alignContent:"center"
            }}>              
            <div style={pantallaNegra}>
                <div style={imagenLogoCentro}></div>
            </div>
 
            </div>
        </div>
    );
}
export default Inicio;
