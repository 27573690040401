import React, { Component, useState, useEffect } from "react";

import IMAGES from "./assets";
import Confetti from "react-confetti";
import WheelComponent from "./weel";
import "./styles.css";
import Ap from "./Ap"
import useServiceData from "./useServiceData";
import { useParams } from "react-router-dom"

const Ruleta = () => {

    let params = useParams()

    const [codigoURL, setCodigoURL] = useState(params.codigo);
    const [mostrarMsgGanador, setMostrarMsgGanador] = useState(false);
    const [iniciarRuleta, setIniciarRuleta] = useState(false); 
    const data = useServiceData("https://"+process.env.REACT_APP_BACKEND_HOST+"/api/ruleta/"+codigoURL);   
    const [datosRuleta, setDatosRuleta] = useState(null);
    const [coloresRuleta, setColoresRuleta] = useState(null);

    const onFinished = (winner) => {
        if(winner != 'Nada'){
            setMostrarMsgGanador(true)    
        }        
    };

    const onIniciarRuleta = () => {
        setIniciarRuleta(true);
    };

    const definirTamañoRuleta = () => {
        let size = 240;        
        if(window.innerWidth >= 480 && window.innerWidth < 500)
        {
            size = 215

        }
        if(window.innerWidth >= 360 && window.innerWidth < 500)
        {
            size = 200

        }
        
        return size;      
    };

    const definirAnchoBtnJugar = () => {
        let size = 500;        
        if(window.innerWidth >= 360 && window.innerWidth < 500)
        {
            size = 450

        }
        
        return size;      
    };

    const definirCentroRuleta = () => {
        let size = 250;        
        if(window.innerWidth >= 480 && window.innerWidth < 500)
        {
            size = 250

        }
        if(window.innerWidth >= 360 && window.innerWidth < 500)
        {
            size = 230

        }
        
        return size;      
    };

    const containerStyle= {
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${IMAGES.background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
}



const logos= {
    height:"150px"
}

const imagenLogo= {
    width:"150px",
    height:"150px",
    float:"right",
    paddingTop:"35px",
    paddingRight:"35px",
    content: `url(${IMAGES.logororomiem})`
}


const pantallaNegra= {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'black',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}


const imagenLogoCentro= {
    width:"150px",
    height:"150px",
    float:"center",
    content: `url(${IMAGES.logororomiem})`,
    alignContent: 'center',
    paddingTop: '30vh',
    display: 'inline-grid'
}

const textoLogo= {
    width:"200px",
    height:"74px",
    float:"left",
    paddingTop:"55px",
    paddingLeft:"35px",
    content: `url(${IMAGES.textologo})`
}

const codigoUtilizado={
    color: 'white',
    textAlign: 'center',
    fontSize: 'xx-large',
    fontFamily: 'fantasy',
    paddingTop: '30vh',
}

const codigo={
    color: 'white',
    textAlign: 'center',
    fontSize: 'x-large',
    fontFamily: 'fantasy',
    float: 'inline-start',
    paddingLeft: '35px',
}


    return (
        <div
        style={containerStyle}>
            <div
            style={{
            alignContent:"center"
            }}>
              
                {mostrarMsgGanador && <Confetti width={window.innerWidth} height={window.innerHeight} />}
                {data && data.habilitado ? (
                    <div>
                    <div style={logos}>
                    <div style={textoLogo}></div>
                    <div style={imagenLogo}></div>
                    
                    </div>
                    <div style={codigo}>CODIGO: {codigoURL}</div>
                    <WheelComponent
                    segments={data.items}
                    segColors={data.colores}
                    winningSegment={"Nada"}
                    onFinished={(winner) => onFinished(winner)}
                    primaryColor="gray"
                    contrastColor="white"
                    buttonText=""
                    isOnlyOnce={true}
                    sizeW={definirTamañoRuleta()}
                    centerXX={definirCentroRuleta()}
                    withBtnJugar={definirAnchoBtnJugar()}
                    codigoURL={codigoURL}                />
                    </div>
                ):(                   
                    data && data.habilitado === false ? 
                    (
                        <div style={codigoUtilizado}>El codigo ya fue utilizado..</div>
                    ):(
                        <div style={pantallaNegra}>
                            <div style={imagenLogoCentro}></div>
                        </div>
                    )        
            
            
                )}
                <div>
                </div> 
            </div>
        </div>
    );
}
export default Ruleta;
