import React, { useEffect, useState } from "react";
//MUI
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
//RECURSOS
import logo from './assets/logo.png';
import giraRuleta from './assets/gira-ruleta.m4a';
import bolaRueda from './assets/bola-rueda.m4a';
import bolaCasillero from './assets/bola-casillero.m4a';
import axios from "axios";

const WheelComponent = ({
  segments,
  segColors,
  winningSegment,
  onFinished,
  primaryColor,
  contrastColor,
  buttonText,
  isOnlyOnce,
  sizeW,
  centerXX,
  withBtnJugar,
  codigoURL
}) => {

  let posicionesGanador = 0;
  let posWinningSegment = 0;
  let currentSegment = "";
  let posCurrentSegment = 0;
  let distanciaCurrentWin = 0;
  const [textoBtn, setTextoBtn] = useState("JUGAR");
  const [isFinished, setFinished] = useState(false);
  const [isStarted, setStarted] = useState(false);
  const [isDobleChance, setDobleChance] = useState(false);

  const audioGiraRulera = new Audio(giraRuleta);
  const audioBolaRueda = new Audio(bolaRueda);
  const audioBolaCasillero = new Audio(bolaCasillero);

  let timerHandle = 0;
  const timerDelay = segments.length;
  let angleCurrent = 0;
  let angleDelta = 0;
  const size = sizeW;
  let canvasContext = null;
  let maxSpeed = Math.PI / `${segments.length}`;
  const upTime = segments.length * 100;
  let downTime = segments.length * 400;
  let spinStart = 0;
  let frames = 0;
  const centerX = centerXX;
  const centerY = 250;
  useEffect(() => {
    wheelInit();
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 0);
  }, []);

  useEffect(() => {
    setStarted(isStarted)
  }, [isStarted]);

  const wheelInit = () => {
    initCanvas();
    wheelDraw();
  };

  const initCanvas = () => {
    let canvas = document.getElementById("canvas");    
    if (navigator.appVersion.indexOf("MSIE") !== -1) {
      canvas = document.createElement("canvas");
      canvas.setAttribute("width", window.innerWidth);
      canvas.setAttribute("height", 700);
      canvas.setAttribute("id", "canvas");
      document.getElementById("wheel").appendChild(canvas);
    }
    canvas.addEventListener("click", spin, false);
    canvasContext = canvas.getContext("2d");
  };

  

  const spin = () => {
    async function fetchPosts() {
      try {
      const response = await axios.get(
        "https://"+process.env.REACT_APP_BACKEND_HOST+"/api/ruleta/jugar/"+codigoURL, {timeout: 10000} 
      );
      //console.log(response.data)
      //winningSegment = "+3.000 fichas"      
      //posWinningSegment = 16      
      //console.log("GANADOR"+winningSegment)
      if(response.status == 200){
        // test for status you want, etc
        console.log(response.status)
    }    
    // Don't forget to return something   
    return response
    }
    catch (err) {
        console.error(err);
    }
    }
    fetchPosts().then(response=>{


      if(response.status == 200){
        winningSegment = response.data?.resultado
        posWinningSegment = response.data?.posicion
      } else {
        window.location.reload();
      }      

      //winningSegment = "Nada"      
      //posWinningSegment=15

      audioGiraRulera.play()
      audioGiraRulera.loop = true
      //console.log(isStarted)
      document.getElementById('canvas').removeEventListener("click", spin);
      if(!isStarted){
      setTextoBtn("...suerte...")
      setStarted(true)
      if (timerHandle === 0) {
        spinStart = new Date().getTime();
        maxSpeed = Math.PI / segments.length-0.08;
        frames = 0;
        timerHandle = setInterval(onTimerTick, timerDelay);
      }
    }
    })   

  };
  let contarCantidad = 0;



  
  const onTimerTick = () => {
    frames++;
    draw();
    const duration = new Date().getTime() - spinStart;
    //console.log("duration:"+duration)
    let progress = 0;
    let finished = false;
    
   
    if (duration < upTime) {
      progress = duration / upTime;
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
      
    } else {
      if (winningSegment) {
        progress = duration / downTime

        if(currentSegment !== winningSegment && progress >= 0.85){
          audioGiraRulera.loop=false
          audioGiraRulera.pause()
          audioBolaRueda.play()
          audioBolaRueda.loop=true
        }

        /*if(frames == 225){
          console.log(distanciaCurrentWin) 
           let add = 0;
         if(distanciaCurrentWin==20){
           downTime = downTime+2000+add  
         }
         if(distanciaCurrentWin==19){
           downTime = downTime+15500+add    
         }
         if(distanciaCurrentWin==18){
           downTime = downTime+1500+add    
         }
         if(distanciaCurrentWin==17){
           downTime = downTime+13000+add    
         }
         if(distanciaCurrentWin==16){
           downTime = downTime+5700+add    
         }
         if(distanciaCurrentWin==15){
           downTime = downTime+12000+add    
         }
         if(distanciaCurrentWin==14){
           downTime = downTime+24800+add  ;  
         }
         if(distanciaCurrentWin==13){
           downTime = downTime+24000+add    
         }
         if(distanciaCurrentWin==12){
           downTime = downTime+23000+add    
         }
         if(distanciaCurrentWin==11){
           downTime = downTime+22000+add    
         }
         if(distanciaCurrentWin==10){
           downTime = downTime+21000+add    
         }
         if(distanciaCurrentWin==9){
           downTime = downTime+20000+add    
         }
         if(distanciaCurrentWin==8){
           downTime = downTime+19000+add    
         }
         if(distanciaCurrentWin==7){
           downTime = downTime+18000+add    
         }
         if(distanciaCurrentWin==6){
           downTime = downTime+17000+add    
         }
         if(distanciaCurrentWin==5){
           downTime = downTime+16000+add    
         }
         if(distanciaCurrentWin==4){
           downTime = downTime+14000+add    
         }
         if(distanciaCurrentWin==3){
           downTime = downTime+7000+add    
         }
         if(distanciaCurrentWin==2){
           downTime = downTime+2000+add    
         }
         if(distanciaCurrentWin==1){
           downTime = downTime+16000+add    
         }
       }*/
       //console.log(distanciaCurrentWin)
        if(progress >= 0.70){
          audioGiraRulera.loop=false
          audioGiraRulera.pause()
          audioBolaRueda.play()
          audioBolaRueda.loop=true
        }
        if(currentSegment !== winningSegment && progress >= 0.80){
          downTime = downTime+300
          if(distanciaCurrentWin==12){
            //downTime = downTime -100
            maxSpeed = 0.06
          }
          if(distanciaCurrentWin==11){
            //downTime = downTime -100
            maxSpeed = 0.06
          }
          if(distanciaCurrentWin==10){
            //downTime = downTime -100
            maxSpeed = 0.06
          }
          if(distanciaCurrentWin==9){
            //downTime = downTime -100
            maxSpeed = 0.06
          }          
          if(distanciaCurrentWin==8){
            //downTime = downTime -100
            maxSpeed = 0.05
          }
          if(distanciaCurrentWin==7){
            //downTime = downTime -100
            maxSpeed = 0.05
          }
          if(distanciaCurrentWin==6){
            //downTime = downTime -100
            maxSpeed = 0.05
          }
          if(distanciaCurrentWin==5){
            //downTime = downTime -100
            maxSpeed = 0.04
          }
  
          if(distanciaCurrentWin==4){
            //downTime = downTime -100
            maxSpeed = 0.035
          }
  
          if(distanciaCurrentWin==3){
            //downTime = downTime -100
            maxSpeed = 0.0275
          } 
  
          if(distanciaCurrentWin==2){
            //downTime = downTime -100
            maxSpeed = 0.02
          }
          if(distanciaCurrentWin==1){
            //downTime = downTime -100
            maxSpeed = 0.01
          }           
        }
       
        if (currentSegment === winningSegment && progress >= 0.90) {

          
          

          downTime = downTime -50
          contarCantidad ++;
          progress = duration / downTime
          angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
          if(contarCantidad==25){
            progress = 1
          }
          
        } else {
          progress = duration / downTime
          angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
        }
      } else {
        progress = duration / downTime
        angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
      }

      if (progress > 0.90 && currentSegment === winningSegment && distanciaCurrentWin==0){
        audioBolaRueda.loop=false
        audioBolaRueda.pause()      
        audioBolaCasillero.play()
      }
      


      if (progress >= 1 && currentSegment === winningSegment && distanciaCurrentWin==0) finished = true
    }
    //console.log("duration:"+duration+ "   maxSpeed:"+ maxSpeed+ "     frames:"+frames+"      uptime:"+upTime+"      downtime:"+downTime+"      progress:"+progress  + "   posCurrent:"+posCurrentSegment+ "                 posWin:"+ posWinningSegment)
    angleCurrent += angleDelta
    while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2
    if (finished) {
      setFinished(true)
      onFinished(currentSegment)
      setTextoBtn(currentSegment)
      clearInterval(timerHandle)
      timerHandle = 0
      angleDelta = 0
      if(currentSegment === 'Doble chance'){
        setDobleChance(true)
      }
    }
  };

  const wheelDraw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const draw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const drawSegment = (key, lastAngle, angle) => {
    const ctx = canvasContext;
    const value = segments[key];
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size, lastAngle, angle, false);
    ctx.lineTo(centerX, centerY);
    ctx.closePath();
    ctx.fillStyle = segColors[key];
    ctx.fill();
    ctx.stroke();
    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate((lastAngle + angle) / 2);
    ctx.fillStyle = contrastColor || "white";
    ctx.font = "bold 1.35em proxima-nova";
    ctx.fillText(value.substr(0, 21), size / 2 + 20, 0);
    ctx.restore();
  };

  const drawWheel = () => {
    const ctx = canvasContext;
    let lastAngle = angleCurrent;
    const len = segments.length;
    const PI2 = Math.PI * 2;
    ctx.lineWidth = 1;
    ctx.strokeStyle = primaryColor || "black";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.font = "1em proxima-nova";
    for (let i = 1; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent;
      drawSegment(i - 1, lastAngle, angle);
      lastAngle = angle;
    }

    // Draw a center circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, 35, 0, PI2, false);
    ctx.closePath();
    ctx.fillStyle = "black";
    ctx.lineWidth = 5;
    ctx.strokeStyle = "#d4af37";
    ctx.fill();
    ctx.font = "bold 1em proxima-nova";
    ctx.fillStyle = "#d4af37";
    ctx.textAlign = "center";
    //ctx.fillText(buttonText || "Roromiem", centerX, centerY + 3);
    let img = new Image();
    img.src = logo;
    img.onload = function (e)
    {
      ctx.drawImage(img, centerXX-28, 220);
    }
    ctx.drawImage(img, centerXX-28, 220);
    ctx.stroke();

    // Draw outer circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, size, 0, PI2, false);
    ctx.closePath();

    ctx.lineWidth = 5;
    ctx.strokeStyle = "#d4af37" || "black";
    ctx.stroke();
  };

  const drawNeedle = () => {
    const ctx = canvasContext;
    ctx.lineWidth = 1;
    ctx.strokeStyle = contrastColor || "white";
    ctx.fileStyle = contrastColor || "white";
    ctx.beginPath();
    ctx.moveTo(centerX + 20, centerY - 31.5);
    ctx.lineTo(centerX - 20, centerY - 31.5);
    ctx.lineTo(centerX, centerY - 60);
    ctx.closePath();
    ctx.fill();
    const change = angleCurrent + Math.PI / 2;
    let i =
      segments.length -
      Math.floor((change / (Math.PI * 2)) * segments.length) -
      1;
    if (i < 0) i = i + segments.length;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = primaryColor || "black";
    ctx.font = "bold 2.5em proxima-nova";
    currentSegment = segments[i];
    posCurrentSegment = i+1;
    distanciaCurrentWin = ((posCurrentSegment - posWinningSegment) < 0) ? segments.length + (posCurrentSegment - posWinningSegment) : posCurrentSegment - posWinningSegment
    //console.log(segments[i])
    //console.log(segments[i-1])
    if(segments[i-1] == posWinningSegment){
      posicionesGanador = 0;
    } else if(segments[i-2] == posWinningSegment){
      posicionesGanador = 1;
    } else if(segments[i-3] == posWinningSegment){
      posicionesGanador = 2;
    } else if(segments[i-4] == posWinningSegment){
      posicionesGanador = 3;
    } else if(segments[i-5] == posWinningSegment){
      posicionesGanador = 4;
    } else if(segments[i-6] == posWinningSegment){
      posicionesGanador = 5;
    } else if(segments[i-7] == posWinningSegment){
      posicionesGanador = 6;
    } else if(segments[i-8] == posWinningSegment){
      posicionesGanador = 7;
    } else if(segments[i-9] == posWinningSegment){
      posicionesGanador = 8;
    } else if(segments[i-10] == posWinningSegment){
      posicionesGanador = 9;
    }
    //console.log(posicionesGanador)
    
  };
  const clear = () => {
    const ctx = canvasContext;
    ctx.clearRect(0, 0, 500, 500);
  };




  const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    padding: '6px 12px',
    border: '2px solid',
    lineHeight: 2.5,
    backgroundColor: '#609550',
    borderColor: '#d4af37',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: '#d4af37',
      borderColor: '#0062cc',
      boxShadow: 'none',
      color: 'white',
      fontSize: 30
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });

  const BootstrapButtonDobleChance = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 30,
    padding: '6px 12px',
    border: '2px solid',
    lineHeight: 2.5,
    backgroundColor: '#ff0000',
    borderColor: '#ff0000',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: '#609550',
      borderColor: '#0062cc',
      boxShadow: 'none',
      color: 'white',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });

  const ruletaYBtn= {
    display:"inline-grid"
}

  return (
    <div style={ruletaYBtn}>
    <canvas
      id="canvas"
      width="500px"
      height="550px"
      style={{
        pointerEvents: isFinished && !isOnlyOnce ? "none" : "auto"
      }}
    />
    <div style={{width: withBtnJugar}}>
      <BootstrapButton  variant="contained" 
                        disableRipple disabled = {isStarted} 
                        startIcon={<EmojiEventsIcon />} 
                        endIcon={<EmojiEventsIcon />} 
                        onClick={()=>spin()}>
                      {textoBtn}
      </BootstrapButton>
    </div>
    {isDobleChance ? (
    <div style={{paddingTop: "10px"}}>
      <BootstrapButtonDobleChance variant="contained" 
                                  disableRipple onClick={()=>window.location.reload()}>
                      Presione para volver a intentar
      </BootstrapButtonDobleChance>
    </div>
    ):(<div></div>)}
    </div>
  );
};
export default WheelComponent;
